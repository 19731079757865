import './bootstrap';

window.Vue = require('vue').default;
import moment from 'moment';
import Form from 'vform';
import Swal from 'sweetalert2';
import VueProgressBar from 'vue-progressbar'
import VueRouter from 'vue-router';
import routes from './routes';

window.Form = Form;

window.Swal = Swal;
const Toast = Swal.mixin({
    toast: true,
    position: 'top-end',
    showConfirmButton: false,
    timer: 3000,
    timerProgressBar: true,
    onOpen: (toast) => {
        toast.addEventListener('mouseenter', Swal.stopTimer)
        toast.addEventListener('mouseleave', Swal.resumeTimer)
    }
})
window.Toast = Toast;

Vue.use(VueProgressBar, {
    color: 'rgb(143, 255, 199)',
    failedColor: 'red',
    height: '3px'
});

import loading from 'vuejs-loading-screen'
Vue.use(loading,{
    bg: 'rgba(0,0,0,0.76)',
    icon: 'fas fa-spinner',
    size: 3,
    icon_color: 'white',
})

Vue.component('pagination', require('laravel-vue-pagination'));

Vue.filter('dateFormat', function (created) {
    return moment(created).format('DD/MM/YYYY');
});
Vue.filter('searchResultsDateFormat', function (ts) {
    let YESTERDAY = moment().clone().subtract(1, 'days').startOf('day');
    let momentDate = moment(ts)
    if (momentDate.isSame(YESTERDAY, 'd')) {
        return 'yesterday'
    } else {
        return momentDate.format('H:m');
    }
});

Vue.filter('yesno', value => (value ? '<i class="fas fa-check green"></i>' : '<i class="fas fa-times red"></i>'));

Vue.use(VueRouter);
const router = new VueRouter({
    mode: 'history',
    base: '/',
    routes
});

// vue-good-table component
import VueGoodTablePlugin from 'vue-good-table';
Vue.use(VueGoodTablePlugin);

// laravel-vue-good-table component
import LaravelVueGoodTable from './components/LaravelVueGoodTable';
Vue.component('laravel-vue-good-table', LaravelVueGoodTable);

import VCalendar from 'v-calendar';
Vue.use(VCalendar);

window.baseUrl = process.env.MIX_BASE_URL + process.env.MIX_API_VERSION

Vue.prototype.$baseUrl = window.baseUrl;
Vue.prototype.$user = window.user;
Vue.prototype.$imageShowUrl = window.imageShowUrl;
Vue.prototype.$therapies = window.therapies;

// data
// Vue.prototype.$countries = window.countries;


// libs
Vue.prototype.$axios = window.axios
Vue.prototype.$moment = moment

const app = new Vue({
    el: '#app',
    router
})

