<template>
    <section class="content">
        <div class="container-fluid">
            <div class="row mb-2">
                <div class="col">
                    <h2>Pazienti</h2>
                </div>
                <div class="col text-right">
                    <button @click.prevent="doExport"
                            class="btn btn-success">Esporta
                    </button>
                </div>
            </div>

            <laravel-vue-good-table
                :data-url="dataUrl"
                config-url="/api/v1/patients/lvgt/config"
                :on-row-click="(params) => onRowClick(params)"
                :fixed-header="true"
                :max-height="maxHeight"
                ref="vgt"
            />
        </div>
    </section>
</template>

<script>


import {downloadFile} from "../services/api/base"
import patients from "../services/api/patients"

export default {
    name: "PeopleCards",
    created() {
        let mainFooter = $('.main-footer').height()
        let vgtGlobalSearch = $('.vgt-global-search').height()
        let vgtWrapFooter = $('.vgt-wrap__footer').height()
        let elementsBuffer = mainFooter + vgtGlobalSearch + vgtWrapFooter + 28

        this.maxHeight = ($('.content-wrapper').height() - elementsBuffer) + 'px'

        if (this.$route.fullPath === '/patients-mine') {
            this.dataUrl = '/api/v1/patients/lvgt/data?mine=true'
        } else {
            this.dataUrl = '/api/v1/patients/lvgt/data'
        }
    },
    data() {
        return {
            maxHeight: '100%',
            dataUrl: ''
        }
    },
    methods: {
        onRowClick(params) {
            let path = this.$route.fullPath === '/patients-mine' ? 'patients-mine/' : 'patients/'
            if (params.event.target.tagName !== 'A') {
                this.$router.push({path: path + params.row.id})
            }
        },
        async doExport() {
            this.$isLoading(true)
            try {
                let response = await patients.doExport(this.$refs.vgt.serverParams)
                downloadFile(response)
            } catch (e) {
                Toast.fire({
                    icon: 'error',
                    title: 'Error: the export can not be done.'
                }).finally()
            }
            this.$isLoading(false)
        }
    }
}
</script>

<style scoped>

</style>
