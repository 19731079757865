export default [
    {
        path: '',
        component: require('./layout/AppWrapper.vue').default,
        children: [
            {
                path: 'patients-mine',
                component: require('./pages/Patients.vue').default
            },
            {
                path: 'patients-mine/:id',
                component: require('./pages/Patient.vue').default
            },
            {
                path: 'patients',
                component: require('./pages/Patients.vue').default
            },
            {
                path: 'patients/:id',
                component: require('./pages/Patient.vue').default
            },
            {
                path: 'invitations',
                component: require('./pages/Invitations').default
            },
            {
                path: 'profile',
                component: require('./pages/Profile').default
            },
            {
                path: '/*',
                component: require('./pages/NotFound.vue').default
            }
        ]
    }
];
