<template>
    <div>

        <navbar></navbar>

        <!-- Main Sidebar Container -->
        <aside class="main-sidebar sidebar-dark-primary elevation-4">
            <router-link to="/" class="brand-link" style="padding-left: 17px;">
                <span class="brand-text font-weight-light">La scuola del paziente</span>
            </router-link>

            <!-- Sidebar -->
            <div class="sidebar">
                <!-- Sidebar user panel (optional) -->
                <div class="user-panel mt-3 pb-3 mb-3 d-flex">
                    <div class="info">
                        <a class="d-block">
                            {{ $user.first_name + ' ' + $user.last_name }}
                        </a>
                        <span class="d-block text-muted"></span>
                    </div>
                </div>

                <!-- Sidebar Menu -->
                <nav class="mt-2">
                    <ul class="nav nav-pills nav-sidebar flex-column" data-widget="treeview" role="menu"
                        data-accordion="false">
                        <li class="nav-item">
                            <router-link to="/patients-mine"
                                         :class="{'active': $route.fullPath ==='/patients-mine' || $route.fullPath.match(/patients-mine\/[0-9]*/)}"
                                         class="nav-link">
                                <i class="nav-icon fas fa-users"></i>
                                <p>Pazienti in carico</p>
                            </router-link>
                        </li>

                        <li class="nav-item">
                            <router-link to="/patients"
                                         :class="{'active': $route.fullPath ==='/patients' || $route.fullPath.match(/patients\/[0-9]*/)}"
                                         class="nav-link">
                                <i class="nav-icon fas fa-people-group"></i>
                                <p>Pazienti registrati</p>
                            </router-link>
                        </li>

                        <li class="nav-item">
                            <router-link to="/invitations"
                                         :class="{'active': $route.fullPath ==='/invitations'}"
                                         class="nav-link">
                                <i class="nav-icon fas fa-user-plus"></i>
                                <p>Richieste di adesione</p>
                            </router-link>
                        </li>

                        <li class="nav-header"></li>
                        <li class="nav-item">
                            <router-link to="/profile"
                                         :class="{'active': $route.fullPath ==='/profile'}"
                                         class="nav-link">
                                <i class="fas fa-user-doctor nav-icon"></i>
                                <p>Profilo</p>
                            </router-link>
                        </li>
                        <li class="nav-item">
                            <a href="#" @click="logout" class="nav-link">
                                <i class="nav-icon fas fa-power-off red"></i>
                                <p>Logout</p>
                            </a>
                        </li>
                    </ul>
                </nav>

                <!-- /.sidebar-menu -->
            </div>
            <!-- /.sidebar -->
        </aside>

        <div class="content-wrapper">
            <!-- Main content -->
<!--            <keep-alive>-->
                <router-view :key="$route.fullPath" max="20"></router-view>
<!--            </keep-alive>-->

            <vue-progress-bar></vue-progress-bar>
        </div>

        <footer class="main-footer">

            <strong>Copyright &copy; {{ $moment().format('YYYY') }}
                <a href=""
                   target="_blank">Apelle</a>.
            </strong>
        </footer>
    </div>
</template>

<script>

import auth from "../services/api/auth";
import Navbar from "./Navbar";
import sharedState from "../sharedState";

export default {
    name: "AppWrapper",
    components: {Navbar},
    data() {
        return {
            sharedState: sharedState
        }
    },
    mounted() {
        if (this.$route.path === '/') {
            this.$router.push({path: 'patients'});
        }
    },
    methods: {
        async logout() {
            await auth.logout()
            window.location = '/login'
        }
    }
}
</script>

<style scoped>

</style>
