<template>
    <div class="form-group">
        <label :for="name" class="control-label">{{ label }}</label>
        <span v-if="note">{{ note }}</span>
        <textarea
            v-model="form[name]"
            class="form-control"
            :id="name"
            :class="{ 'is-invalid': form.errors.has(name) }"
            v-on:keyup="liveCountDown"
            :rows="rows"
            :readonly="readonly"
        />
        <span v-show="limitmaxCount > 0" v-bind:class="{'text-danger': generateErr }">
                <small>Chars remaining: {{ totalRemainCount }}/{{ limitmaxCount }}</small>
            </span>
        <has-error :form="form" :field="name"></has-error>

    </div>
</template>

<script>
import {HasError} from "vform/src/components/bootstrap4";

export default {
    name: "TextAreaInput",
    components: {HasError},
    props: {
        label: String,
        name: String,
        form: Object,
        limitmaxCount: {type: Number, default: 0},
        rows: {type: Number, default: 5},
        note: {type: String, default: null},
        readonly: {type: Boolean, default: false},
    },
    data() {
        return {
            totalRemainCount: this.limitmaxCount,
            generateErr: false
        }
    },
    methods: {
        liveCountDown: function () {
            if (this.form[this.name]) {
                this.totalRemainCount = this.limitmaxCount - this.form[this.name].length;
                this.generateErr = this.totalRemainCount < 0;
            }
        }
    },
    updated() {
        this.liveCountDown()
    }
}
</script>

<style scoped>

</style>
