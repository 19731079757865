import http from "./base"

export default {
    index(queryString) {
        return http().get('/patients' + (queryString !== undefined ? '?q=' + queryString : ''))
    },
    show(id) {
        return http().get('/patients/' + id)
    },
    delete(id) {
        return http().delete('/patients/' + id)
    },
    doExport(data) {
        return http({
            timeout: -1,
            responseType: 'blob'
        }).post('/export/patients', data)
    },
    saveDoctorComment(id, data) {
        return http().put('/revaluations/' + id, data)
    },
    addNewRevaluation(therapyId, data) {
        return http().post('/revaluations/' + therapyId, data)
    },
    addNewTherapy(patientId, data){
        return http().post('/therapies/' + patientId, data)
    }
}
