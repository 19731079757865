<template>
    <div class="container-fluid">
        <div class="row mb-2">
            <div class="col">
                <h2>Richieste di adesione</h2>
            </div>
        </div>

        <div class="row">
            <div class="col">
                <div class="card">
                    <div class="card-body">
                        <div class="row">
                            <div class="col">
                                <ul class="list-group list-group-unbordered" v-if="invitations.length > 0">
                                    <li class="list-group-item" v-for="invitation in invitations">
                                        <div class="row">
                                            <div class="col-10">
                                                {{ invitation.patient.first_name }} {{ invitation.patient.last_name }},
                                                inviata il {{ invitation.created_at |dateFormat }}
                                            </div>
                                            <div class="col-2 text-right">
                                                <button class="btn btn-sm btn-primary" @click="accept(invitation.id)">accetta</button>
                                            </div>
                                        </div>
                                    </li>
                                </ul>
                                <div v-else>
                                    <small>Non sono presenti richieste di adesione</small>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import invitations from "../services/api/invitations"

export default {
    name: "Invitations",
    data() {
        return {
            invitations: []
        }
    },
    async created() {
        await this.initializeContent()
    },
    methods: {
        async initializeContent() {
            this.$isLoading(true)
            try {
                let response = await invitations.index()
                this.invitations = response.data.data
            } catch (e) {
                console.error(e)
                Toast.fire({
                    icon: 'error',
                    title: 'Errore: i dati non possono essere recuperati'
                }).finally()
            }

            this.$isLoading(false)
        },
        async accept(id) {
            this.$isLoading(true)
            try {
                await invitations.accept(id)
                let response = await invitations.index()
                this.invitations = response.data.data
                Toast.fire({
                    icon: 'success',
                    title: 'Adesione accettata'
                }).finally()
            } catch (e) {
                console.error(e)
                Toast.fire({
                    icon: 'error',
                    title: 'Errore: l\'operazione non può essere completata'
                }).finally()
            }

            this.$isLoading(false)
        }
    }
}
</script>

<style scoped>

</style>
