<template>
    <section class="content">
        <div class="container-fluid">
            <div class="row mb-2">
                <div class="col">
                    <h2>{{ title }}</h2>
                </div>
                <div class="col text-right">

                    <!--                    <button v-if="readonly"-->
                    <!--                            @click.prevent="toggleReadonly"-->
                    <!--                            class="btn btn-secondary">Edit-->
                    <!--                    </button>-->
                </div>
            </div>

            <div class="row">
                <div class="col">
                    <div class="card">
                        <div class="card-body">
                            <div class="row">
                                <div class="col">
                                    <ul class="list-group list-group-unbordered">
                                        <li class="list-group-item">
                                            Nome
                                            <strong>{{ patient.first_name }}</strong>
                                        </li>
                                        <li class="list-group-item">
                                            Cognome
                                            <strong>{{ patient.last_name }}</strong>
                                        </li>
                                        <li class="list-group-item">
                                            Data di nascita
                                            <strong>{{ patient.birth_date | dateFormat }}</strong>
                                        </li>
                                    </ul>
                                </div>

                                <div class="col">
                                    <ul class="list-group list-group-unbordered">
                                        <li class="list-group-item">
                                            Email
                                            <strong>{{ patient.email }}</strong>
                                        </li>
                                        <li class="list-group-item">
                                            Telefono
                                            <strong>{{ patient.phone }}</strong>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div class="row" v-if="!displayNewTherapyForm">
                <div class="col">
                    <div class="card">
                        <div class="card-body">
                            <div class="row">
                                <div class="col">
                                    <div class="row">
                                        <div class="col">
                                            <h5>Terapia in corso: {{ patient.current_therapy?.therapy_name }}</h5>
                                        </div>
                                    </div>
                                    <div class="row">
                                        <div class="col">
                                            <ul class="list-group list-group-unbordered">
                                                <li class="list-group-item">
                                                    Iniziata il
                                                    <strong>{{
                                                            patient.current_therapy?.start_at | dateFormat
                                                        }}</strong>
                                                </li>
                                                <li class="list-group-item">
                                                    Dosaggio
                                                    <strong>{{ patient.current_therapy?.dosage }}</strong>
                                                </li>
                                                <li class="list-group-item">
                                                    Tempi di risposta attesi
                                                    <strong>{{
                                                            patient.current_therapy?.expected_response_time
                                                        }}</strong>
                                                </li>
                                                <li v-if="patient.current_therapy?.expected_revaluations.length > 0"
                                                    class="list-group-item">
                                                    Prossima rivalutazione attesa il
                                                    <strong>{{
                                                            patient.current_therapy.expected_revaluations[0]?.expected_at | dateFormat
                                                        }}</strong>
                                                </li>
                                                <li v-if="patient.current_therapy?.expected_revaluations.length < 1"
                                                    class="list-group-item">
                                                    Nessuna rivalutazione prevista
                                                </li>
                                                <li v-if="patient.current_therapy?.expected_revaluations.length < 1"
                                                    class="list-group-item">
                                                    <v-date-picker class="inline-block h-full" v-model="date"
                                                                   :min-date="new Date()">
                                                        <template v-slot="{ inputValue, togglePopover }">
                                                            <div class="flex items-center">
                                                                <small>seleziona una data</small>
                                                                <input
                                                                    :value="inputValue"
                                                                    @focus="togglePopover"
                                                                    class="bg-white text-gray-700 w-full py-1 px-2 appearance-none border rounded-r focus:outline-none focus:border-blue-500"
                                                                    readonly
                                                                />
                                                                <button class="btn btn-primary btn-sm" v-if="isMine"
                                                                        @click="addNewRevaluation">Programma una
                                                                    nuova
                                                                    rivalutazione
                                                                </button>
                                                            </div>
                                                        </template>
                                                    </v-date-picker>
                                                </li>
                                            </ul>
                                        </div>
                                    </div>

                                    <div class="row" v-if="isMine">
                                        <div class="col text-right pt-3">
                                            <div class="col text-right" style="padding-top:-5px;">
                                                <button class="btn btn-sm btn-warning"
                                                        @click="toggleDisplayNewTherapyForm">Inizia nuova terapia
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div class="row" v-if="displayNewTherapyForm">
                <div class="col">
                    <div class="card">
                        <div class="card-body">
                            <div class="row">
                                <div class="col">
                                    <div class="row">
                                        <div class="col">
                                            <h5>Nuova terapia</h5>
                                        </div>
                                    </div>
                                    <div class="row">
                                        <div class="col">
                                            <ul class="list-group list-group-unbordered">
                                                <li class="list-group-item">
                                                    Terapia
                                                    <div class="form-group">
                                                        <select v-model="therapy" class="form-control">
                                                            <option>Seleziona</option>
                                                            <option v-for="therapy in $therapies" :value="therapy.id">
                                                                {{ therapy.name }}
                                                            </option>
                                                        </select>
                                                    </div>
                                                </li>
                                                <li class="list-group-item">
                                                    Data di inizio
                                                    <v-date-picker class="inline-block h-full" v-model="startDate">
                                                        <template v-slot="{ inputValue, togglePopover }">
                                                            <div class="flex items-center">
                                                                <small>seleziona una data</small>
                                                                <input
                                                                    :value="inputValue"
                                                                    @focus="togglePopover"
                                                                    class="bg-white text-gray-700 w-full py-1 px-2 appearance-none border rounded-r focus:outline-none focus:border-blue-500"
                                                                    readonly
                                                                />
                                                            </div>
                                                        </template>
                                                    </v-date-picker>
                                                </li>
                                                <li class="list-group-item">
                                                    Dosaggio
                                                    <div class="form-group">
                                                        <input type="text" v-model="dosage" class="form-control"/>
                                                    </div>
                                                </li>
                                                <li class="list-group-item">
                                                    Tempi di risposta attesi
                                                    <div class="form-group">
                                                        <input type="text" v-model="expectedResponseTime"
                                                               class="form-control"/>
                                                    </div>
                                                </li>
                                                <li class="list-group-item">
                                                    Prima rivalutazione attesa il
                                                    <v-date-picker class="inline-block h-full"
                                                                   v-model="revaluationDate">
                                                        <template v-slot="{ inputValue, togglePopover }">
                                                            <div class="flex items-center">
                                                                <small>seleziona una data</small>
                                                                <input
                                                                    :value="inputValue"
                                                                    @focus="togglePopover"
                                                                    class="bg-white text-gray-700 w-full py-1 px-2 appearance-none border rounded-r focus:outline-none focus:border-blue-500"
                                                                    readonly
                                                                />
                                                            </div>
                                                        </template>
                                                    </v-date-picker>
                                                </li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div class="row">
                                <div class="col text-right pt-3">
                                    <div class="col text-right" style="padding-top:-5px;">
                                        <button class="btn btn-sm btn-success" @click="addNewTherapy">Inserisci nuova
                                            terapia e interrompi la precedente
                                        </button>
                                        <button class="btn btn-sm btn-warning" @click="toggleDisplayNewTherapyForm">
                                            Annulla
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div v-if="!displayNewTherapyForm">
                <div class="row mb-2">
                    <div class="col">
                        <h2>Rivalutazioni</h2>
                    </div>
                </div>

                <div class="row">
                    <div class="col-12" v-for="(revaluation,key) in patient.current_therapy?.revaluations_done">
                        <div class="card">
                            <div class="card-body">
                                <div v-if="key === 0" class="ribbon-wrapper ribbon-lg">
                                    <div class="ribbon bg-success text-xs">
                                        più recente
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="col">
                                        <h5>Data <strong>{{ revaluation.revaluation_at | dateFormat }}</strong>
                                        </h5>
                                        Valutazione della risposta
                                        <vue-slider ref="slider" v-model="revaluation.evaluation" :min="0" :max="10"
                                                    :disabled="true" :tooltip="'hover'"></vue-slider>

                                        Entità del prurito
                                        <vue-slider ref="slider" v-model="revaluation.itching" :min="0" :max="10"
                                                    :disabled="true" :tooltip="'hover'"></vue-slider>

                                        Entità del dolore
                                        <vue-slider ref="slider" v-model="revaluation.pain" :min="0" :max="10"
                                                    :disabled="true" :tooltip="'hover'"></vue-slider>
                                    </div>

                                    <div class="col">
                                        <div class="row mb-2">
                                            <div class="col">
                                                <strong>Commento paziente</strong><br>
                                                <small v-if="!revaluation.patient_comment">Nessun commento</small>
                                                {{ revaluation.patient_comment }}
                                            </div>
                                        </div>

                                        <div class="row" v-if="key === 0">
                                            <div class="col">
                                                <strong>Commento medico</strong><br>
                                                <div class="row">
                                                    <div class="col">
                                                        <div class="form-group" v-if="isMine">
                                                        <textarea v-model="revaluation.doctor_comment"
                                                                  class="form-control" rows="5"></textarea>
                                                        </div>
                                                        <div class="row" v-else>
                                                            <div class="col">
                                                                <small v-if="!revaluation.doctor_comment">Nessun
                                                                    commento</small>
                                                                {{ revaluation.doctor_comment }}
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="row">
                                                    <div class="col text-right">
                                                        <button class="btn btn-primary btn-sm" v-if="isMine"
                                                                @click="saveDoctorComment(revaluation.id)">
                                                            Salva
                                                        </button>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="row" v-else>
                                            <div class="col">
                                                <strong>Commento medico</strong><br>
                                                <small v-if="!revaluation.doctor_comment">Nessun commento</small>
                                                {{ revaluation.doctor_comment }}
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div class="row mt-3" v-if="revaluation.images.length > 0">
                                    <div class="col">
                                        <a v-for="image in revaluation.images"
                                           :href="$imageShowUrl+'/'+image.id" target="_blank">
                                            <img :src="$imageShowUrl+'/'+image.id+'?width=100&height=100'" alt=""
                                                 class="img-thumbnail"/>
                                        </a>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>
</template>

<script>
import sharedState from "../sharedState"
import patients from "../services/api/patients"
import vueSlider from "vue-slider-component/src/vue2-slider.vue"
import TextAreaInput from "../components/TextAreaInput"

export default {
    name: "Patient",
    components: {vueSlider, TextAreaInput},
    data() {
        return {
            sharedState: sharedState,
            title: '',
            patientId: null,
            therapyForm: new Form(),
            revaluationForms: [],
            readonly: false,
            patient: {},
            date: null,
            displayNewTherapyForm: false,
            therapy: null,
            startDate: null,
            dosage: null,
            expectedResponseTime: null,
            revaluationDate: null,
            isMine: false
        }
    },
    async created() {
        await this.initializeContent()
    },
    methods: {
        async initializeContent() {
            this.$isLoading(true)
            this.form = new Form()
            this.patientId = this.$route.params.id
            if (this.patientId) {
                try {
                    this.patient = await this.getPatient()
                    this.isMine = this.patient.active_doctor[0].id === this.$user.id
                    this.title = this.fullName
                } catch (e) {
                    console.error(e)
                    Toast.fire({
                        icon: 'error',
                        title: 'Errore: i dati non possono essere recuperati'
                    }).finally()
                }

            }
            this.$isLoading(false)
        },
        async save() {
            this.$isLoading(true)
            try {
                let response = await this.form.put(window.baseUrl + '/people-cards/' + this.peopleCardId)

                this.originalData = await this.getPatient()
                Toast.fire({
                    icon: 'success',
                    title: response.data.message
                }).finally()
            } catch (e) {
                console.error(e)
                Toast.fire({
                    icon: 'error',
                    title: 'Error: the form could not be saved.'
                }).finally()
            }
            this.toggleReadonly()
            this.$isLoading(false)
        },
        async getPatient() {
            let response = await patients.show(this.patientId)
            return response.data.data
        },
        async saveDoctorComment(id) {
            this.$isLoading(true)
            try {
                let response = await patients.saveDoctorComment(id, {doctor_comment: this.patient.current_therapy.revaluations_done.filter(r => r.id === id)[0].doctor_comment})
                this.patient = await this.getPatient()
                Toast.fire({
                    icon: 'success',
                    title: 'Commento inserito con successo'
                }).finally()
            } catch (e) {
                console.error(e)
                Toast.fire({
                    icon: 'error',
                    title: 'Errore nel salvataggio'
                }).finally()
            }
            this.$isLoading(false)
        },
        async addNewRevaluation() {
            if (this.date === null) {
                Toast.fire({
                    icon: 'error',
                    title: 'È necessario inserire una data di rivalutazione'
                }).finally()
                return
            }
            this.$isLoading(true)
            try {
                let response = await patients.addNewRevaluation(this.patient.current_therapy.id, {expected_at: this.date})
                this.patient = await this.getPatient()
                this.date = null
                Toast.fire({
                    icon: 'success',
                    title: 'Nuova rivalutazione programmata con successo'
                }).finally()
            } catch (e) {
                console.error(e)
                Toast.fire({
                    icon: 'error',
                    title: 'Errore nel salvataggio'
                }).finally()
            }
            this.$isLoading(false)
        },
        toggleDisplayNewTherapyForm() {
            this.displayNewTherapyForm = !this.displayNewTherapyForm
        },
        async addNewTherapy() {
            if (this.therapy === null
                || this.startDate === null
                || this.dosage === null
                || this.expectedResponseTime === null
                || this.revaluationDate === null) {
                Toast.fire({
                    icon: 'error',
                    title: 'È necessario completare tutti i campi'
                }).finally()
                return
            }
            this.$isLoading(true)
            try {
                let response = await patients.addNewTherapy(this.patient.id, {
                    therapy: this.therapy,
                    start_at: this.startDate,
                    dosage: this.dosage,
                    expected_response_time: this.expectedResponseTime,
                    expected_at: this.revaluationDate
                })
                this.patient = await this.getPatient()
                this.toggleDisplayNewTherapyForm()

                this.therapy = null
                this.startDate = null
                this.dosage = null
                this.expectedResponseTime = null
                this.revaluationDate = null

                Toast.fire({
                    icon: 'success',
                    title: 'Nuova terapia inserita con successo'
                }).finally()

            } catch (e) {
                console.error(e)
                Toast.fire({
                    icon: 'error',
                    title: 'Errore nel salvataggio'
                }).finally()
            }
            this.$isLoading(false)
        },
    },
    computed: {
        fullName() {
            let first_name = (this.patient.first_name !== null) ? this.patient.first_name : ''
            let last_name = (this.patient.last_name !== null) ? this.patient.last_name : ''
            return first_name + ' ' + last_name
        }
    }
}
</script>

<style scoped>

</style>
