import http from "./base"

export default {
    profile() {
        return http().get('/profile')
    },
    profileUpdate(data) {
        return http().put('/profile', data)
    },
    changePassword(data) {
        return http().post('/change-password', data)
    }
}
