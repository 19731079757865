import axios from 'axios'
import fileDownload from "js-file-download";

export default function http(params = null) {

    let conf = {
        baseURL: window.baseUrl,
        timeout: 30000,
        headers: {
            'Accept': 'application/json'
        }
    }

    if (params !== null) {
        if (params.timeout) {
            conf.timeout = params.timeout
        }
        if (params.responseType) {
            conf.responseType = params.responseType
        }
    }

    let ax = axios.create(conf)

    ax.interceptors.response.use(function (response) {
        return response;
    }, function (error) {
        if (401 === error.response.status || 403 === error.response.status || 419 === error.response.status) {
            window.location = window.loginUrl;
        } else {
            return Promise.reject(error);
        }
    });
    return ax
}

export function downloadFile(response){
    let filename = ''
    let disposition = response.headers['content-disposition']
    if (disposition && disposition.indexOf('attachment') !== -1) {
        let filenameRegex = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/
        let matches = filenameRegex.exec(disposition)
        if (matches != null && matches[1]) {
            filename = matches[1].replace(/['"]/g, '')
        }
    }
    let fileDownload = require('js-file-download');
    fileDownload(response.data, filename);
}
