<template>
    <section class="content">
        <div class="container-fluid">
            <div class="row mb-2">
                <div class="col">
                    <h2>Profilo utente</h2>
                </div>
            </div>
            <div class="row">
                <div class="col">
                    <div class="card">
                        <div class="card-body">
                            <div class="row">
                                <div class="col" v-if="profile">
                                    <div class="form-group">
                                        <label for="first_name">Nome</label>
                                        <input type="text"
                                               id="first_name"
                                               v-model="profile.first_name"
                                               class="form-control"/>
                                    </div>

                                    <div class="form-group">
                                        <label for="last_name">Cognome</label>
                                        <input type="text"
                                               id="last_name"
                                               v-model="profile.last_name"
                                               class="form-control"/>
                                    </div>

                                    <div class="form-group">
                                        <label for="title">Titolo</label>
                                        <input type="text"
                                               id="title"
                                               v-model="profile.title"
                                               class="form-control"/>
                                    </div>

                                    <div class="form-group">
                                        <label for="email">Email</label>
                                        <input type="email"
                                               id="email"
                                               v-model="profile.email"
                                               class="form-control"/>
                                    </div>
                                </div>
                            </div>

                            <div class="row">
                                <div class="col text-right">
                                    <button class="btn btn-sm btn-success"
                                            @click="updateProfile">Salva
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div class="container-fluid">
            <div class="row mb-2">
                <div class="col">
                    <h2>Cambio password</h2>
                </div>
            </div>
            <div class="row">
                <div class="col">
                    <div class="card">
                        <div class="card-body">
                            <div class="row">
                                <div class="col" v-if="profile">
                                    <div class="form-group">
                                        <label for="first_name">Password attuale</label>
                                        <input type="password"
                                               id="first_name"
                                               v-model="current_password"
                                               class="form-control"/>
                                    </div>

                                    <div class="form-group">
                                        <label for="last_name">Nuova password</label>
                                        <input type="password"
                                               id="last_name"
                                               v-model="password"
                                               class="form-control"/>
                                    </div>

                                    <div class="form-group">
                                        <label for="title">Conferma password</label>
                                        <input type="password"
                                               id="title"
                                               v-model="passwordConfirmation"
                                               class="form-control"/>
                                    </div>
                                </div>
                            </div>

                            <div class="row">
                                <div class="col text-right">
                                    <button class="btn btn-sm btn-success"
                                            @click="passwordChange">Salva
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>
</template>

<script>
import doctor from "../services/api/doctor"

export default {
    name: "Profile",
    data() {
        return {
            profile: null,
            current_password: null,
            password: null,
            passwordConfirmation: null
        }
    },
    async created() {
        this.$isLoading(true)
        try {
            let response = await doctor.profile()
            this.profile = response.data.data
        } catch (e) {
            console.error(e)
            Toast.fire({
                icon: 'error',
                title: 'Errore: i dati non possono essere recuperati'
            }).finally()
        }
        this.$isLoading(false)
    },
    methods: {
        async updateProfile() {
            this.$isLoading(true)
            try {
                await doctor.profileUpdate(this.profile)
                Toast.fire({
                    icon: 'success',
                    title: 'Profilo aggiornato con successo'
                }).finally()
                this.$user = this.profile
            } catch (e) {
                console.error(e)
                Toast.fire({
                    icon: 'error',
                    title: 'Errore: i dati non possono essere salvati'
                }).finally()
            }
            this.$isLoading(false)
        },
        async passwordChange() {
            this.$isLoading(true)
            try {
                await doctor.changePassword({
                    current_password: this.current_password,
                    password: this.password,
                    password_confirmation: this.passwordConfirmation
                })
                Toast.fire({
                    icon: 'success',
                    title: 'Password aggiornata correttamente'
                }).finally()
            } catch (e) {
                console.error(e)
                Toast.fire({
                    icon: 'error',
                    title: 'Errore: i dati non possono essere salvati'
                }).finally()
            }
            this.$isLoading(false)
        }
    }
}
</script>

<style scoped>

</style>
