import http from "./base"

export default {
    index() {
        return http().get('/invitations')
    },
    accept(id) {
        return http().post('/invitations/' + id + '/accept')
    },
}
