export default {
    // cardsOpened: [],
    // pushCardOpen(card) {
    //     if (this.cardsOpened.filter(e => e.name === card.name).length === 0) {
    //         this.cardsOpened.push(card)
    //     }
    // },
    // updateCardOpen(card) {
    //     let index = this.cardsOpened.findIndex(item => item.id === card.id && item.type === card.type)
    //     this.cardsOpened[index] = card
    // },
    // popCardOpened(id, type) {
    //     let index = this.cardsOpened.findIndex(item => item.id === id && item.type === type)
    //     this.cardsOpened.splice(index,1)
    //     return  this.cardsOpened[index !== 0 ? (index - 1) : 0]
    // },
    // clearCardsOpened() {
    //     this.cardsOpened = []
    // },
};
