<template>
    <!-- Navbar -->
    <nav class="main-header navbar navbar-expand bg-white navbar-light border-bottom">
        <!-- Left navbar links -->
        <ul class="navbar-nav">
            <li class="nav-item">
                <a class="nav-link" data-widget="pushmenu" href="#"><i class="fa fa-bars"></i></a>
            </li>
        </ul>

    </nav>
    <!-- /.navbar -->
</template>

<script>
import sharedState from "../sharedState";

export default {
    name: "Navbar",
    data() {
        return {
            sharedState: sharedState
        }
    },
    methods: {
    }
}
</script>

<style scoped>

</style>
